import React, { PureComponent } from 'react'
import { Link, graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import classnames from 'classnames'
import Layout from '../components/layout'
import SEO from '../components/seo'

import arrow from '../images/cta-arrow.svg'
import PageHeader from '../components/PageHeader'

export const query = graphql`
  query {
    prismic {
      aboutUs: allAbout_us_pages {
        edges {
          node {
            title
            body
            sections {
              section_title
              section_text
            }
          }
        }
      }
    }
  }
`

function createSerializer(
  { paragraphClassname } = { paragraphClassname: () => '' }
) {
  return function serializer(type, element, content, children, index) {
    if (type === 'paragraph') {
      return (
        <p
          key={index}
          className={paragraphClassname({
            type,
            element,
            content,
            children,
            index,
          })}
        >
          {children}
        </p>
      )
    }

    return null
  }
}

const bodySerializer = createSerializer({
  paragraphClassname({ index }) {
    return classnames('text-lg font-normal leading-loose', {
      'mt-8': index === 0,
      'mt-4': index > 0,
    })
  },
})

const sectionSerializer = createSerializer({
  paragraphClassname() {
    return 'font-sans leading-loose mt-4 text-base'
  },
})

export default class AboutUsPage extends PureComponent {
  render() {
    const data = this.props.data.prismic.aboutUs.edges[0].node
    return (
      <Layout>
        <SEO title="About Us" keywords={[`longevity`]} />

        <div className="bg-hero-image-0-50 pb-24 lg:pb-32">
          <PageHeader titles={[data.title]} richDescription={data.body} />
          <div className="container lg:max-w-xl px-8 lg:px-0 mx-auto font-serif text-blue-dianne">
            <div className="lg:w-1/2 lg:ml-auto">
              {data.sections.map((section, index) => (
                <div key={index} className="mt-16">
                  <h2 className="font-normal text-xl">
                    {section.section_title}
                  </h2>
                  {RichText.render(
                    section.section_text,
                    undefined,
                    sectionSerializer
                  )}
                </div>
              ))}
              <div className="flex mt-12">
                <Link
                  to="/focus-areas"
                  className="font-serif text-lg text-blue-dianne bg-selago px-8 py-5 flex items-center no-underline"
                >
                  <span className="cta-ie11-fix">Show Focus Areas</span>{' '}
                  <img src={arrow} alt="" className="ml-8 h-6" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
